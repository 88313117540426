import React from 'react'
import CategoryTitle from "../helpers/categoryTitle.amp";
import ArticlePreview from "../../templates/articlePreview.amp";
import {isMobile} from "react-device-detect";
import {adDisplayDelay, delayMultiplicationFactor} from "../../utils/articleUtil";

class ArticlePopularPosts extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        setTimeout(() => {
            const self = this;
            try {
                window.googletag.cmd.push(function () {
                    const slot3 = googletag.defineSlot('/22218985137/pensiontimes/pt_rightcol_desktop', [300, 250], 'div-pt_rightcol_desktop');
                    if (slot3) {
                        slot3.addService(googletag.pubads());
                        googletag.pubads().enableSingleRequest();
                        googletag.pubads().collapseEmptyDivs();
                        googletag.pubads().setCentering(true);
                        googletag.pubads().disableInitialLoad();
                        googletag.enableServices();
                        /*self.adRefreshInterval = setInterval(function () {
                            googletag.pubads().refresh([slot3]);
                        }, consts.AD_REFRESH_TIME);*/
                    }
                });
            } catch (e) {
                console.log(e);
            }
            try {
                googletag.cmd.push(function () {
                    googletag.display('div-pt_rightcol_desktop');
                });
            } catch (e) {
                console.log(e);
            }
        }, adDisplayDelay() * delayMultiplicationFactor(isMobile));
    }

    render() {
        return <div className="popular-posts">
            <CategoryTitle title="Popular Posts"/>
            {
                this.props.articles.map((article, index) => {
                    return <div key={`pp-${article.slug}`}>
                        <div className="popular-post-container-link">
                            <ArticlePreview key={article.slug}
                                            customClass="bottom-border"
                                            data={article}
                                            noAuthor={true}
                                            noImage={index !== 0}
                                            imageMeta={{width: 768}}
                                            noBody={true}/>
                        </div>
                        {
                            {
                                1: <div className="ad-container">
                                    <div className="advertisement-text">Advertisement</div>
                                    <div id='div-pt_rightcol_desktop' style={{height: "250px"}}></div>
                                </div>
                            }[index]
                        }
                    </div>
                })
            }
        </div>
    }
}

export default ArticlePopularPosts;
